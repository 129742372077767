import React, { useContext } from "react";
import {
  EmailShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  FacebookShareButton,
  EmailIcon,
  LinkedinIcon,
  RedditIcon,
  TwitterIcon,
  FacebookIcon,
} from "react-share";
import { cn } from "../lib/helpers";
import { SiteContext } from "./global/site-context";

export const ShareWidgets = ({ title, excerpt, path, vertical, size }) => {
  const site = useContext(SiteContext);

  const iconProps = {
    size: size || 30,
    borderRadius: 99,
    // iconFillColor: "#000",
    bgStyle: { fill: "currentColor" },
    // round: true,
  };
  const buttonProps = {
    title,
    url: `${process.env.HOST}${path}?utm_medium=share`,
    className:
      "transform transition-size scale-100 hover:scale-110 hover:opacity-70",
  };

  return (
    <div
      className={cn(
        "flex flex-wrap",
        vertical ? "flex-col gap-4" : "gap-4 sm:gap-8"
      )}
    >
      <span className="hover:text-link">
        <LinkedinShareButton
          {...buttonProps}
          summary={excerpt}
          source="Common Room"
        >
          <LinkedinIcon {...iconProps} />
        </LinkedinShareButton>
      </span>
      <span className="hover:text-link">
        <TwitterShareButton
          {...{
            ...buttonProps,
            title: site.token("twitterShare"),
          }}
          related={[site.twitter]}
        >
          <TwitterIcon {...iconProps} />
        </TwitterShareButton>
      </span>
      <span className="hover:text-link">
        <RedditShareButton {...buttonProps}>
          <RedditIcon {...iconProps} />
        </RedditShareButton>
      </span>
      <span className="hover:text-link">
        <FacebookShareButton {...buttonProps} quote={title}>
          <FacebookIcon {...iconProps} />
        </FacebookShareButton>
      </span>
      <span className="hover:text-link">
        <EmailShareButton {...buttonProps} subject={title}>
          <EmailIcon {...iconProps} />
        </EmailShareButton>
      </span>
    </div>
  );
};
